import React, { useState } from "react"
import { Toast } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CONTACT_FORM_URL =
  "https://9inhlpzmnj.execute-api.us-east-1.amazonaws.com/production/contact_form"

const EMAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const INITIAL_STATE = {
  name: "",
  email: "",
  subject: "",
  message: "",
}

const imageQuery = graphql`
  query {
    contactImg: file(relativePath: { eq: "contact_gotit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ContactForm = () => {
  const { contactImg } = useStaticQuery(imageQuery)
  const [form, setForm] = useState(INITIAL_STATE)
  const [toastVisible, setToastVisible] = useState(false)

  const changeField = changes => setForm({ ...form, ...changes })

  const validForm = () =>
    form.email.match(EMAIL_FORMAT) && form.message.length > 0

  const sendForm = async e => {
    e.preventDefault()

    if (!validForm()) return

    await fetch(CONTACT_FORM_URL, {
      method: "POST",
      body: JSON.stringify(form),
    })

    setForm(INITIAL_STATE)
    setToastVisible(true)
  }

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">Contacto</h2>
              <p className="description">
                Estamos aquí para escucharte. ¿Cómo podemos ayudar en tu
                proyecto?
              </p>
            </div>
            <div className="form-wrapper">
              <form onSubmit={sendForm}>
                <label htmlFor="item01">
                  <input
                    type="text"
                    name="name"
                    id="item01"
                    value={form.name}
                    onChange={e => {
                      changeField({ name: e.target.value })
                    }}
                    placeholder="Nombre"
                  />
                </label>

                <label htmlFor="item02">
                  <input
                    type="text"
                    name="email"
                    id="item02"
                    value={form.email}
                    onChange={e => {
                      changeField({ email: e.target.value })
                    }}
                    placeholder="E-mail"
                  />
                </label>

                <label htmlFor="item03">
                  <input
                    type="text"
                    name="subject"
                    id="item03"
                    value={form.subject}
                    onChange={e => {
                      changeField({ subject: e.target.value })
                    }}
                    placeholder="Asunto"
                  />
                </label>
                <label htmlFor="item04">
                  <textarea
                    type="text"
                    id="item04"
                    name="message"
                    value={form.message}
                    onChange={e => {
                      changeField({ message: e.target.value })
                    }}
                    placeholder="Mensaje"
                  />
                </label>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Enviar
                </button>
              </form>
              <Toast
                onClose={() => setToastVisible(false)}
                show={toastVisible}
                delay={3000}
                autohide
                className="mt--10"
              >
                <Toast.Body>
                  Gracias por el mensaje. Lo contestaremos lo antes posible.
                </Toast.Body>
              </Toast>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <Img fluid={contactImg.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
