import React from "react"
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Footer from "../components/footer"
import ContactForm from "../components/contactForm"

const Contact = () => (
  <Layout title="Contacto">
    <div className="slider-wrapper">
      <Slider title="Contacto" />
    </div>

    <div className="rn-contact-top-area ptb--120 bg_color--5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="rn-address">
              <div className="icon">
                <FiPhone />
              </div>
              <div className="inner">
                <h4 className="title">Teléfono</h4>
                <p>
                  <a href="tel:+34659210552">+34 659 210 552</a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
            <div className="rn-address">
              <div className="icon">
                <FiMail />
              </div>
              <div className="inner">
                <h4 className="title">E-mail</h4>
                <p>
                  <a href="mailto:info@gotit.es">info@gotit.es</a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
            <div className="rn-address">
              <div className="icon">
                <FiMapPin />
              </div>
              <div className="inner">
                <h4 className="title">Dirección</h4>
                <p>
                  C/ Aribau 191, 1-2
                  <br />
                  08021 Barcelona
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="rn-contact-page ptb--120 bg_color--1">
      <ContactForm />
    </div>

    <Footer />
  </Layout>
)

export default Contact
